@keyframes blinker {
  50% {
    opacity: 0;
  }
}

body {
  background-color: transparent !important;

  .herobg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    // overflow: hidden;
    overflow: auto;

    .video {
      position: absolute;
      filter: opacity(25%);
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      -o-object-fit: cover;
      object-fit: cover;
      background: #232a34;
      width: 100%;
      height: 100vh;
    }

    .main-container {
      padding: 6rem;

      @media only screen and (max-width: 1390px) {
        padding: 3rem;
      }

      @media only screen and (max-width: 450px) {
        padding: 1.5rem;
      }

      .logo {
       width: 500px;
       height: auto;
      }

      .forms-inputs {
        position: relative;

        input {
          border: 2px solid #eee;

          &:focus-visible {
            border: 1px solid #2028eb !important;
            outline: none !important;
            background-color: transparent !important;
            box-shadow: none !important;
          }
        }

        select {
          border: 2px solid #eee;

          &::after {
            border: 1px solid #6149cd !important;
            outline: none !important;
            background-color: transparent !important;
          }

          &:focus-visible {
            border: 1px solid #6149cd !important;
            outline: none !important;
            background-color: transparent !important;
          }
        }

        label {
          color: #656565;
          opacity: 1;
          position: absolute;
          background-color: #fefefe;
        }

        .label1-focused {
          color: rgb(32, 40, 235) !important;
          font-weight: 600;
          outline: none !important;
        }

        .label1-notfocused {
          color: #656565;
          outline: none !important;
        }

        .label2-focused {
          color: rgb(32, 40, 235) !important;
          font-weight: 600;
          outline: none !important;
        }

        .label2-notfocused {
          color: #656565;
          outline: none !important;
        }
      }

      .eng_text {
        font: 2.875rem !important;
        color: #14213d;
        font-weight: 300;
      }

      .jap_text {
        font-size: 1.4rem !important;
      }

      .keep_sign_check {
        //appearance: none;
        vertical-align: middle;
        width: 30px;
        border: 2px solid #14213d;
        background-color: white;
      &:checked{
        accent-color: #6793bd;
      }
      }
     

      .keep_sign_label {
        color: #14213d !important;
        vertical-align: middle;
        color: rgb(20, 33, 61, 1);
        font-size: 17px !important;
        font-weight: 500 !important;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .error {
    color: red;
  }
.forget-pass-popup {
    .modal-content {
      padding: 40px 0;
      h5 {
        text-transform: capitalize;
        font-weight: 400;
      }
    }
    .modal-body {
      max-width: 400px;
      margin: auto;
      input {
        margin: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      button {
        width: 100%;
        margin: 10px 0 0;
        padding: 12px;
        border-radius: 4px;
        background-color: #ccc;
      }
    }
  }
  .logo-vis-img {
    margin-right: 70px;
  }
}
.click-here-checkbox-color{
 color: #6793bd;

      
}
