.file-outer-border {
    border: 2px solid #006872;
    .drag {
      font-size: 16px;
    }
    .file {
      position: relative;
      display: block;
      width: 100%;
      font-size: 37px;
      text-align: center;
      text-transform: uppercase;
      border: 2px dashed #ccc;
      padding: 2rem;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
      &:active,
      &:focus {
        border-color: #09f;
      }
      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        outline: 0;
        cursor: pointer;
      }
    }
  }
  
  .pre-line {
    white-space: pre-line;
  }