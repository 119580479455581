$mobile-width: 460px;
$tablet-width: 768px;
$desktop-width: 992px;
$middle-desktop-width: 1366px;
$big-desktop-width: 1920px;
$extra-large-desktop-width: 2640px;

@mixin desktop {
  @media (min-width: #{$desktop-width + 1}) {
    @content;
  }
}

@mixin middleDesktopAndLess {
  @media (max-width: #{$middle-desktop-width}) {
    @content;
  }
}

@mixin smallDesktopAndLess {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tabletAndLess {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin middleDesktop {
  @media (min-width: #{$desktop-width + 1}) and (max-width: #{$middle-desktop-width}) {
    @content;
  }
}

@mixin smallDesktop {
  @media (min-width: #{$tablet-width + 1}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width + 1}) and (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin bigDesktop {
  @media (max-width: #{$big-desktop-width}) {
    @content;
  }
}

@mixin xlargeDestop {
  @media (max-width: #{$extra-large-desktop-width}) {
    @content;
  }
}
