.makeStyles-drawerPaper-8 {
  width: 245px !important;
  position: fixed !important;
  min-height: fit-content;
  overflow-x: hidden;
  flex-direction: column !important;
  height: calc(100vh - 55px) !important;
}

.makeStyles-drawer-7 {
  width: 245px !important;
  position: sticky !important;
}

.MuiListItemIcon-root {
  min-width: 19px !important;

  // padding-left: 8px;
  p {
    font-size: 20px !important;
  }
}

.MuiTypography-root {
  width: 150px !important;

  &:last-child {
    width: 100% !important;
  }
}

.root-ja-font {
  .MuiTypography-body1 {
    width: 169px !important;
    font-size: 13px !important;
  }
}

.MuiPaper-root {
  color: #fff !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  height: calc(100vh - 55.45px) !important;
}

.MuiListItem-button:hover {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiListItem-root {
  padding-top: 8px !important;
  padding-bottom: 8px !important;


  // @media only screen and (max-width: 1250px) {
  //   padding-top: 0px !important;
  //   padding-bottom: 0px !important;
  // }

  &:hover {
    background-color: #eac21d !important;
  }

  &:hover {
    background-color: #eac21d !important;
  }

  &:last-child {
    background-color: #f7f8f9 !important;

    :hover {
      button {
        background-color: #eac21d !important;
      }
    }
  }
}

ul .MuiButtonBase-root.Mui-selected {
  background-color: #eac21d !important;
  color: #fff !important;
}

// header
.lang-switch {
  width: max-content;
  display: flex;
  align-self: center;
  position: absolute;
  top: 12px;
  right: 64px;
  padding: 4px;
  border-radius: 4px;
  z-index: 10;
  background-color: #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;

  @media (min-width: 768px) {
    top: 16px;
  }

  @media (min-width: 992px) {
    right: 24px;
  }

  @media (min-width: 1200px) {
    top: 10px;
  }

  label {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: bold;
    background-color: transparent;
    color: #77838f;
    font-family: "SF Pro Text";
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    cursor: pointer;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  input {
    display: none;
  }
}

.single-switch input:checked~label {
  background-color: #fff;
}

.jss8 {
  width: 245px !important;
  display: flex;
  position: fixed !important;
  // flex-direction: unset;
}

.main-menu {
  background: #44403f !important;
  color: #f8f9fa;
  padding: 8px;
  font-weight: 600;
  font-size: 16px;
}

.main-menu-headline {
  color: #f8f9fa !important;
}

.MuiSvgIcon-root {
  font-size: 14px !important;
}

.MuiList-root {
  width: 245px !important;
}


.MuiDrawer-paper {
  top: 0px !important;
}

.makeStyles-toolbar-9 {
  min-height: 0px !important;
}

.MuiDrawer-paperAnchorLeft {
  top: 66px !important;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.vh-fix {
  height: calc(100vh - 55.95px) !important;
  min-width: 650px;
}

.sidebar-icons {
  width: 20px;
}

table {
  td {
    vertical-align: middle;
    border-radius: 3px;
    word-break: break-all;
  }
}

ul {
  .border-bottom {
    color: #7d7d7d !important;
    background: #efefef;

    &:hover {
      color: #fff !important;
    }
  }
}

.select-class {
  height: 26px;
}

.refresh {
  width: max-content;
  align-self: center;
  display: flex;
  border-radius: 4px;
  z-index: 10;
  position: absolute;
  right: 338px;
  top: 21px;

  @media (max-width: 980px) {
    top: 19px !important;
    right: 368px !important;
  }
}

.tenant-option {
  width: max-content;
  align-self: center;
  display: flex;
  border-radius: 4px;
  z-index: 10;
  right: 180px;
  position: absolute;
  z-index: 10;
  top: 11px;

  @media (max-width: 980px) {
    top: 19px !important;
    right: 215px !important;
  }
}

button {
  &.add-btn {
    background: transparent !important;
    color: #12213c !important;
  }
}

.japan-font-family {
  font-family: "Noto Sans JP", sans-serif;
}

.english-font-family {
  font-family: "Helvetica";
}

.MuiListItem-gutters {
  padding-left: 16px !important;
  padding-right: 40px !important;
}

.form-check {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
}

.form-check-input {
  width: 20px !important;
  height: 20px !important;
  float: none !important;
}


.tab-content {
  border: 1px solid #a9b1bd;
  height: 95%;
  padding: 14px 0;
  border-radius: 0 0 10px 10px;

  .card {
    table {
      border-collapse: separate;
      border-spacing: 8px 6px;
      text-align: center;
      overflow-x: auto;

      th {
        border: none;
        color: #2d382d;

        @media (max-width: 1600px) {
          width: 130px;
        }

        &:last-child {
          @media (max-width: 1600px) {
            width: 250px;
          }
        }

        button {
          color: #14213d;
        }
      }
    }
  }
}

.pass-headings {
  h4 {
    font-size: 14px;
    font-weight: 600;
    color: #12213c;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #12213c;

    span {
      color: #a9b1bd;
    }
  }
}

#operational-table {
  height: 153px;
  overflow: scroll;
}

.operational-analysis-setting-tables {
  position: relative;

  tr {
    td {
      height: 35px;
    }

    .btn {
      padding: 0px 10px;
    }
  }
}


@media (min-width: 992px) {
  .operational-setting-sub-modal {

    .modal-lg,
    .modal-xl {
      --bs-modal-width: 950px;
    }
  }
}

.research-management-cont {
  .card {
    table {
      border-collapse: separate;
      border-spacing: 8px 6px;
      text-align: center;
      overflow-x: auto;

      th {
        border: none;
        color: #14213d;

        @media (max-width: 1600px) {
          width: 130px;
        }

        &:last-child {
          @media (max-width: 1600px) {
            width: 300px;
          }
        }

        button {
          color: #14213d;
        }
      }
    }
  }
}

.login-form {
  h5 {
    color: #485057;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

table.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #efefef;
  padding: 0;
}

table>tbody>tr>* {
  --bs-table-accent-bg: #efefef;
  color: #14213d !important;
  padding: 0rem 1rem 0rem 1rem !important;
}

table.table-striped>tbody>tr td.btn-contain {
  --bs-table-accent-bg: #44403f;
  padding: 0px !important;
}

table.table-striped>tbody>tr td.btn-contain button {
  width: 100%;
  padding: 18px;
  height: 100%;
}

table.table-striped>tbody>tr:nth-of-type(odd)>td.bg-info,
table>tbody>tr>td.bg-info {
  --bs-table-accent-bg: #eece58
}

table.table-striped>tbody>tr:nth-of-type(odd)>td.bg-transparent,
table>tbody>tr>td.bg-transparent {
  --bs-table-accent-bg: transparent;
  border: 0;
}

table tbody button.btn {
  color: #fff;
  height: 100%;
  width: 100%;
  padding: 18px 10px;
}

button.btn {
  &.btn-green {
    background: #66ab79;
    border-color: #66ab79;
    color: #fff;

    &:hover {
      background: #66ab79;
      border-color: #66ab79;
      color: #fff;
    }
  }
}

.csv-container {
  h5 {
    color: #44403f;
    text-align: center;
    margin: 20px 0px;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
  }

  h4 {
    padding: 0 35px;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    animation: none;
  }

  button {
    width: 100%;
    min-width: 200px;
    border-radius: 4px;
  }
}

.add-agent-popup {
  width: 100%;

  .modal-body {

    input,
    select,
    textarea {
      border: 1px solid #c1c1c1;
      background: transparent;
      font-size: 14px;
      padding: 4px 10px;
      min-height: 40px;
      margin-bottom: 10px;
    }

    input[type="checkbox"] {
      min-height: auto;
      margin-bottom: 4px;
    }
  }
}

.files-dropzone {
  border: 2px dashed #14213d;
  cursor: pointer;
  color: #14213d;
  padding: 30px 0;
}

.logo-upload {
  width: 200px;
  border: 2px dashed;
  text-align: center;
}

.cus-tab-container {
  li {
    button {
      &.nav-link {
        border: 1px solid #a9b1bd !important;
        border-bottom: none !important;
      }

      &.nav-link.active {
        background-color: #44403F !important;
      }
    }
  }
}

.quick-sight-dashboard-container {
  width: 100vw;
  margin: 20px;
  color: #44403f;
  font-size: 15px;
  border: none;

  .quick-sight-dashboard {
    textarea {
      border: 0;
      background: #f8f9fa;
      padding: 10px;
      color: #1e213b;
      border-radius: 3px;
      border: none;
    }

    h5 {
      color: #44403f;
      padding: 10px;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
      border-radius: 3px;
      border: none;
    }

    .card-footer-action {
      background: #c9caca;
      padding: 12px;
      border-radius: 3px;
      border: none;

      h6 {
        color: #44403f;
        font-size: 18px;
        margin-bottom: 5px;
        border-radius: 3px;
        border: none;
      }

      .status-btn {
        pointer-events: none;
        border: none;

        button {
          background: #66ab79;
          border: 1px solid #66ab79;
          padding: 6px;
          color: #fff;
          text-transform: uppercase;
          font-weight: 600;
          width: 120px;
          border-radius: 3px;
          border: none;

          &.completed {
            background: #66ab79;
            border: 1px solid #66ab79;
            border: none;
          }

          &.not_yet {
            background: #d57b85;
            border: 1px solid #d57b85;
            border: none;
          }

          &.need-update {
            background: #eece58;
            border: 1px solid #eece58;
            border: none;
          }

          &.in-progress {
            background: #d57b85;
            border: 1px solid #d57b85;
          }

          &.need-updated {
            background: #eece58;
            border: 1px solid #eece58;

          }
        }
      }

      .updated-btn {
        pointer-events: none;
        border: none;

        button {
          background: #fcf4da;
          color: #12213c;
          border: 1px solid #fcf4da;
          padding: 6px 12px;
          width: 120px;
          border-radius: 3px;
          border: none;
        }
      }

      .data-btn {
        button {
          background: #7d7d7d;
          color: #fff;
          border: 1px solid #7d7d7d;
          padding: 6px 12px;
          border-radius: 50px;
          border: none;

          &.bg-info {
            border: 1px solid #027eff;
            background-color: #027eff !important;
            border: none;
          }
        }
      }
    }
  }
}

.office-sample-btn {
  margin: 0;
  bottom: 15px;
  position: absolute;
  z-index: 9999999;
  left: 12%;
  max-width: 200px;
  border: none;
  left: 30px !important
}

.fnt-14 {
  font-size: 14px;
}

// quicksight-embedding-iframe
.quicksight-embedding-iframe {
  margin: 0px;
  padding: 0px;
  border: none;
}

.grid-padding {
  padding: 16px !important;

  @media(max-width: 1300px) {
    padding: 4px !important;
  }
}

.admin_comments {
  font-size: 16px;
  border: none;
  border-radius: 3px;
  width: 100%;
  border: 2px solid #c9caca;
  padding: 5px;
}

.MuiTypography-body1 {
  width: 169px !important;
  font-size: 14px !important;
  line-height: 1.0rem !important;
  font-weight: 550 !important;
}

.child-layout {
  margin-top: 85px
}

.bg-clr9fa {
  background-color: #f8f9fa;
}

.bg-clrded {
  background-color: #ededed;
}

.btn-6793b6 {
  background-color: #6793bd;
  border-radius: 20px;
  border: none;
  color: white;

  a:link {
    color: white;
  }

  a:visited {
    color: white;
  }

  a:hover {
    color: white;
  }

  a:active {
    color: white;
  }
}

.btn-6793b6:disabled {
  opacity: 0.2 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.btn-44403f {
  padding: 0px 30px;
  background-color: #44403f;
  border-radius: 20px;
  border: none;
  color: white;
}

.labelExcel {
  font-size: larger;
  display: flex;
  justify-content: center;
  color: white;
  background-color: #6793bd;
}

.checkbox-container {
  input[type="checkbox"] {
    appearance: none;
    margin: 2px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    position: relative;
    margin-right: 10px;
  }

  input[type=checkbox]:checked {
    background-color: initial;
    color: initial;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
    accent-color: #6793bd;
  }
}

.btn-submit {
  background: #eac21d !important;
  color: #fff !important;
  opacity: 83% !important;
  border: none;
  font-size: 16px !important;
  margin-left: 6px !important;
  width: 180px;

}


.csvlistTable {
  border-spacing: 0px;

  thead {
    height: 36px;
    margin-bottom: 10px;
  }

  tbody {
    margin-top: 20px !important;

    tr {
      td {
        padding-top: 20px;
        vertical-align: initial;
        height: 40px;
      }
    }
  }

  .csvSelect {
    border-radius: 2px;
    border-color: #ededed !important;
  }
}

.flag {
  overflow-y: scroll !important;
  height: 100px !important;
}

.holidays {
  width: 40%;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.space-analysis-tooltip .tooltip-inner {
  background: #44403f;
  max-width: 100%;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: #44403f !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: #44403f !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #44403f !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #44403f !important;
}