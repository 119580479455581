@import "./responsive.scss";

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: end;
}

#iframeBlock {
  height: 100%;
  padding: 0;
  margin: 0;

  .iframeBody {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    iframe {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .iframe-withoutTab {
    height: 100%;

    iframe {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    // @include middleDesktopAndLess {
    //   height: 404px;
    // }

    // @include xlargeDestop {
    //   height: 721px;
    // }
  }
}