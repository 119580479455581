.file_outer {
  background: #ffff !important;
  .margin_inner {
    border-radius: 10px;
    background-color: white;
    .file_inner_div {
      background: #f8f9fa;
      border: 4px dashed #14213d;
      border-radius: 10px;
      .icon {
        font-size: 5rem !important;
      }
    }
  }
}

.files_dropzone {
  border: 0;
}
.btn_14214D,
.btn_14214D :hover,
.btn_14214D :active,
.btn_14214D :visited {
  border: 4px solid #1a1f56 !important;
  color: 2px #1a1f56 !important;
  text-shadow: 0mm;
  opacity: 100% !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.btn_14215D,
.btn_14215D :hover,
.btn_14215D :active,
.btn_14215D :visited {
  color: #14213d !important;
  font-size: 8px;
  opacity: 100%;
}
.drag_file_text {
  color: #14213d;
  opacity: 70%;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 1.6rem;
}

.upload_file_text {
  color: #14213d;
}

.box {
  select {
    background-color: #14213d !important;
    color: white !important;
    background-image: url("../../../assets/img/arrow.svg") !important;
    font-size: 17px;
    .options {
      &:checked {
        content: "." !important;
        font-size: 17px !important;
      }
    }
  }
}

@media only screen and (min-width: 1250px) {
  .file_inner_div {
    width: 100% !important;
  }
}

.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none !important;
  background-color: rgba(254, 171, 87, 0.5) !important;
}
