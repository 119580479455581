@import "../../../assets/styles/_responsive.scss";

ul {
  background-color: transparent !important;

  .nav-item {
    button {
      width: 100% !important;
      background-color: white !important;
      color: #14213d !important;
    }

    .active {
      background-color: #44403f !important;
      color: #fff !important;
    }
  }
}

textarea {
  &::placeholder {
    padding-left: 4px !important;
  }
}

.tab-content {
  height: 95%;

  .tab-pane {
    height: 100%;
  }
}

.analysis-height {
  height: 50.5625rem;
  overflow-y: scroll;

  @include middleDesktop {
    height: 29.5625rem !important;
    overflow: scroll;
  }
}

.set-iframe-height {
  height: 594px !important;
  overflow: auto;

  @include middleDesktopAndLess {
    height: 539px !important;
  }
}

#iframeBlock {
  height: 100%;

  .iframeBody {
    height: 100%;
    width: 100%;

    iframe {
      border: none;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }
}