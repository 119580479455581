@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans JP', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
  scrollbar-color: #eac21d #ffffff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clr-14213D {
  color: #14213d;
}

.fnt-wgt-500 {
  font-weight: 500;
}

.fnt-wgt-600 {
  font-weight: 600;
}

.fnt-wgt-900 {
  font-weight: 900;
}

.fnt-wgt-700 {
  font-weight: 700;
}

.btn-EAC21D,
.btn-EAC21D :hover,
.btn-EAC21D :active,
.btn-EAC21D :visited {
  background: #eac21d !important;
  color: #fff !important;
  opacity: 83% !important;
  border: none;
  font-size: 12px !important;
  margin-left: 6px !important;
}

.btn-EAC21D-office-sample {
  background: #eac21d !important;
  color: #fff !important;
  opacity: 83% !important;
  border: none;
  font-size: 12px !important;
  margin-left: 6px !important;
}

.btn-EAC21D-office-sample:hover {
  background: #44403F !important;
}

.btn-EAC21D-office-sample-large {
  background: #eac21d !important;
  color: #fff !important;
  opacity: 83% !important;
  border: none;
  height: 60px;
  width: 300px;
  font-size: 20px !important;
  margin-left: 15px !important;
}



.disabled {
  opacity: 0.65 !important;
  color: white !important;
  pointer-events: none !important;
}

.btn-14213D,
.btn-14213D :hover,
.btn-14213D :active,
.btn-14213D :visited {
  background-color: #44403f !important;
  color: white !important;
}

.btn-44403f {
  background-color: #44403f !important;
  color: white !important;
}

.btn-44403f:disabled {
  opacity: 0.2 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.fnt-16 {
  font-size: 16px;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fc850d;
}

.vh-fix {
  min-height: calc(100vh - 55.95px) !important;
}

.op-100 {
  opacity: 1 !important;
}

.op-100:disabled {
  opacity: 0.65 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.btn-2EA56D,
.btn-2EA56D :hover,
.btn-2EA56D :active,
.btn-2EA56D :visited {
  background: #66ab79 !important;
  color: #fff !important;
  border: none;
}

.btn-E65C4F,
.btn-E65C4F :hover,
.btn-E65C4F :active,
.btn-E65C4F :visited {
  background: #d57b85 !important;
  color: #fff !important;
  border: none;
}

.btn-d3d5fb,
.btn-d3d5fb :hover,
.btn-d3d5fb :active,
.btn-d3d5fb :visited {
  background: #5c5c5c !important;
  color: #fff !important;
  border: none;
}

.text-blink {
  animation: blinker 1s linear infinite;
}

table {
  table-layout: fixed;
  white-space: nowrap;
  border-collapse: separate !important;
}

th button {
  top: 0;
  position: sticky;
  background: white !important;
  font-weight: 600 !important;
}

th button:hover {
  background-color: #495057 !important;
  color: white !important;
}

thead button.ascending::after {
  content: "\25BC";
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: "\25B2";
  display: inline-block;
  margin-left: 1em;
}

.badge {
  background-color: #14213d;
  font-size: 16px !important;
  margin-bottom: 0;
}

.badge-light {
  background-color: antiquewhite;
  color: #14213d !important;
}

.accessLog>table>tr>td {
  padding: 5px 10px;
}

.wifiManual>table>tr>td {
  padding: 5px 10px;
}

/* cost-simulation/room-simulation  */
.btn-simulate {
  height: 35px;
  width: 60%;
  background-color: #eac21d !important;
  font-size: 15px;
  font-weight: bold;
  color: white !important;
  margin: 0px;
  border-radius: 3px;
  border-style: hidden
}

.btn-save {
  height: 35px;
  width: 60%;
  background-color: #5f8fb4 !important;
  font-size: 15px;
  font-weight: bold;
  color: white !important;
  margin: 0px;
  border-radius: 3px;
  border-style: hidden
}

.param {
  border-style: hidden;
  height: 30px;
  padding-left: 20px;
  padding-top: 5px;
}

.param-input {
  border-style: hidden;
  width: 90%;
  height: 25px;
}

.simulation-plan {
  font-weight: bold;
  color: #454648;
  height: 0px;
  padding-left: 5px;
}

.simulation-plan-frame {
  border: 1px #454648 solid;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 5px;
  width: 80%;
  box-sizing: border-box;
}

.container-title {
  width: 300px;
}

.container-pattern {
  background-color: #efefef;
  height: 50px;
}

.container-params-room-simulation {
  background-color: #efefef;
  margin-bottom: 10px;
}

.container-params-cost-simulation {
  background-color: #efefef;
  margin-bottom: 10px;
}

.container-params {
  background-color: #F7F8F9;
}

.container-dashboard {
  background-color: #efefef;
  height: 750px;
  width: 100%;
}

.container-dashboard-embedding {
  background-color: #efefef;
  height: 750px;
  width: 100%;
}

/* space-analysis */
.btn-description {
  margin-left: 10px;
  margin-bottom: 20px;
  height: 50px;
  width: 200px;
  background-color: #5f8fb4 !important;
  font-size: 20px;
  color: white !important;
  border-radius: 15px;
  border-style: hidden
}

.select-box {
  margin-bottom: 20px;
  height: 50px;
  width: 300px;
  background-color: white !important;
  font-size: 20px;
  margin-left: 50px;
  color: black !important;
  border-radius: 15px;
  border: 2px solid #5c5c5c;
  border: none;
}

.select-box-long {
  margin-bottom: 20px;
  height: 50px;
  width: 400px;
  background-color: white !important;
  font-size: 20px;
  margin-left: 50px;
  color: black !important;
  border-radius: 15px;
  border: 2px solid #c9caca;
}



.container-image {
  height: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #efefef;
}

.container-image-not-found {
  height: 300px;
  width: 600px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #5c5d5c;
  background-color: #efefef;
}


/* top-page */
.top-page-analysis-container {
  background-color: #efefef;
  height: 100%;
  width: 100%;
}

.top-page-analysis-title {
  background-color: #efefef;
  padding: 10px 0px 0px 20px;
  font-size: 25px;
  font-weight: 800;
  border: none;
}

.top-page-analysis-title-senarios {
  background-color: white !important;
  padding: 10px 0px 20px 20px;
  font-size: 25px;
  font-weight: 800;
  border: none;
}

.top-page-content-no-data {
  background-color: #efefef;
  color: #44403f;
  font-size: 20px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 40px !important;
  margin: 0;
}

.top-page-content-1row {
  height: 140px;
  width: 100%;
  padding: 10px;
  margin: 0;
  background-color: #efefef;
  overflow: hidden;
}


.top-page-content-kpi {
  height: 140px;
  width: 200px;
  padding: 10px;
  margin: 0;
  background-color: #efefef;
  overflow: hidden;
}

.top-page-content-2rows {
  height: 280px;
  padding: 10px;
  margin: 0;
  background-color: #efefef;
  overflow: hidden;
}

.top-page-content-3rows {
  height: 320px;
  padding: 10px;
  margin: 0;
  background-color: #efefef;
  overflow: hidden;
}

.top-page-text-area {
  width: 100%;
}

.top-page-setting-title {
  color: #44403f;
  font-size: 30px;
}

.kpi-card-title {
  color: #44403f;
  font-size: 20px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  margin: 0;
}

.kpi-card-value-and-unit {
  height: 100%;
}

.kpi-card-value {
  color: #44403f;
  font-size: 2.5em;
  font-weight: 900;
  max-width: 80%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding-left: 10px;
}

span[style="autoshrink"] {
  color: #44403f;
  font-size: 1.3em;
  font-weight: 900;
  max-width: 80%;
}

.kpi-card-value-frame {
  text-align: left;
  white-space: nowrap;
  padding-top: 0.1em;
  padding-left: 10px;
  height: 100px;
  width: 100%;
}

/* .kpi-card-value-flex {
  height: 100%;
  color: #44403f;
  font-size: 1.75em;
  font-weight: 900;
  max-width: 80%;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
} */

.kpi-card-value-unit {
  font-size: 30px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  padding: 10;
  margin-left: 5px;
}

.survey-notification-modal-text-area {
  width: 100%;
  height: 28px;
  background-color: white !important;
  font-size: 20px;
  margin-left: 20px;
  color: black !important;
  border: none;
  text-align: center
}

.survey-notification-modal-input-area {
  width: 100%;
  height: 28px;
  background-color: white !important;
  font-size: 20px;
  margin-left: 20px;
  color: black !important;
  border: 1px #454648 solid;
  border-radius: 3px;
}

.survey-notification-modal-title {
  width: 100%;
  height: 28px;
  background-color: white !important;
  font-size: 20px;
  margin-left: 20px;
  color: black !important;
  border: none;
  font-weight: bold;
}

.survey-notification-modal-comment {
  width: 100%;
  height: 28px;
  background-color: white !important;
  font-size: 12px;
  margin-left: 20px;
  color: black !important;
  border: none;
}


/* commute simulation */
.commute-info {
  height: 60vh;
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin: 0;
  padding: 0;
}

.commute-kpi {
  grid-area: 1 / 1 / 3 / 6;
}

.location-map {
  grid-area: 2 / 3 / 3 / 6;
}

.upladedLogoName>p {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  color: #999999;
}

.uploadlogoImage {
  width: 200px;
  height: 70px;
  margin-right: 10px;

}

.custom-modal .modal-dialog {
  max-width: 90%;
  width: 90%;
  margin: 0 auto;
}

.custom-modal .modal-content {
  height: 90vh;
  overflow-y: auto;
}



/* take survey */
.question-title-answered {
  background-color: #efefef;
}
.suv-description {
  font-size: small;
  color: #eac21d;
  font-weight: 500;
}
/* AccountManagement */

.input-box {
  width: 300px;
}

/* Top Page */

.table-row {
  height: 20px;
}

.ellipsis {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
