@import "../../src/assets/styles/responsive";

.fixheight {
  height: calc(100vh - 55.45px);
}
.card-body-height {
  max-height: 500px;
  overflow-y: scroll;

  @include middleDesktopAndLess {
    max-height: 460px;
  }
  .card {
    max-height: 500px;
  }
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}


*::-webkit-scrollbar-thumb { 
  border-radius: 10px;
  background-color: #eac21d;
  height: 25%; /* color of the scroll thumb */
}

.scrollbar-c9c9ca{
  *::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #c9c9ca ;
    height: 25%; /* color of the scroll thumb */
  }
}

.sv-root-modern {
  overflow-y: scroll;

  height: 100%;
  @include middleDesktop {
    min-height: 500px !important;
    max-height: 500px !important;
  }
  @include smallDesktopAndLess {
    min-height: 500px !important;
    max-height: 500px !important;
  }
}

.sv-root-modern .sv-completedpage {
  color: white !important;
  background-color: #495057 !important;
}

.sv-container-modern__title {
  padding-top: 0em !important ;
}

.sv-action-bar {
  display: flex !important;
}

.btn-6793bd,
.btn-6793bd :hover,
.btn-6793bd :active,
.btn-6793bd :visited{
  background-color: #6793bd !important;
  color:white !important;
}
