.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.lang {
  font-size: 16px;
  padding: 5px 0px;
}
.ja-font {
  font-size: 13px;
  padding: 3px 0px;
}

.searchTenant:focus{
  border: none !important;
  box-shadow: 0px 0px 0px 0px  !important;
}
.css-1hb7zxy-IndicatorsContainer>div:nth-child(3){
  display: none !important;
}
.css-13cymwt-control>.css-1hb7zxy-IndicatorsContainer{
  display: none;
}
.css-1u9des2-indicatorSeparator{
  background-color: transparent !important
}
.css-13cymwt-control{
  width:150px !important
}
